<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("products.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("products.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("products.configuration") }}
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="
                showAction(1) && withPermissionName(permissions.CAT_PRO_ADD_01)
              "
              @click="changeAction(2)"
            >
              <i class="feather icon-plus mr-1"></i> {{ $t("global.create_products") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="feather icon-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-shopping-bag mr-2"></i
                      >{{ $t(titleAction) }}
                    </h5>
                  </div>
                </div>
              </div>

              <div v-show="showAction(1)">
                <TableComponent
                  ref="tableProd"
                  :headers="headers"
                  :isButton="true"
                  @excelTable="excelTable"
                  @updateRecord="edit"
                  :isUpdate="withPermissionName(permissions.CAT_PRO_EDIT_02)"
                />
              </div>

              <div v-if="showAction(2) || showAction(3) || showAction(4)">
                <div class="card">
                  <div class="card-body">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="doAction">
                        <div class="row">
                          <div class="col-md-12 pb-0">
                            <h6>
                              <v-icon class="pb-1"
                                >mdi-numeric-1-box-outline</v-icon
                              >
                              {{ $t("products.information_products") }}
                            </h6>
                          </div>
                          <div class="col-md-8">
                            <validation-provider
                              v-slot="{ errors }"
                              name="causes"
                              rules="required|max:50"
                            >
                              <v-text-field
                                v-model="model.productKey"
                                :counter="50"
                                :error-messages="errors"
                                :label="$t('label.causes')"
                                required
                                autocomplete="off"
                              ></v-text-field>
                            </validation-provider>
                          </div>
                          <div class="col-md-4">
                            <validation-provider
                              v-slot="{ errors }"
                              name="unit_of_measure"
                              rules="required"
                            >
                              <v-autocomplete
                                :items="unitsMeasurement"
                                v-model="model.unitMeasurement"
                                :error-messages="errors"
                                :no-data-text="$t('label.no_results')"
                                :label="$t('label.unit_of_measure')"
                              >
                              </v-autocomplete>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <validation-provider
                              v-slot="{ errors }"
                              name="description"
                              rules="required|max:800"
                            >
                              <v-textarea
                                outlined
                                v-model="model.description"
                                name="description"
                                :label="$t('label.description')"
                                :error-messages="errors"
                                required
                                :counter="800"
                                :maxlength="800"
                                rows="3"
                                row-height="20"
                              ></v-textarea>
                            </validation-provider>
                          </div>
                        </div>

                        <div class="col-md-12 text-right">
                          <button
                            class="btn btn-outline-success"
                            type="submit"
                            :disabled="invalid || sending"
                          >
                            <i class="feather icon-save mr-1"></i>
                            {{ $t("global.save_products") }}
                          </button>
                        </div>
                      </form>
                    </validation-observer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import TableComponent from "@/common/table/TableComponent";
import { PRODUCTS } from "@/core/DataTableHeadersLocale";

import { UtilFront, PermissionConstants } from "@/core";
import { ProductsRequest, CategoryRequest, ExcelRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      title: "products.products",
      remmisionList: [],
      selected: [],
      list: [],
      headers: PRODUCTS(),
      search: "",
      action: 1,
      sending: false,
      model: {
        idProduct: "",
        productKey: "",
        name: "",
        description: "",
        unitMeasurement: 0,
        categories: [],
      },
      index: 0,
      categories: [],
      service: ProductsRequest,
      unitsMeasurement: [],
      permissions: PermissionConstants,
      loading: null,
    };
  },
  computed: {
    titleAction() {
      if (this.action == 1) {
        return "products.products";
      } else if (this.action == 2) {
        return "products.register_products";
      } else if (this.action == 3) {
        return "products.edit_products";
      }
      return "Listado";
    },
  },
  methods: {
    showAction(action) {
      return this.action == action;
    },
    async changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {
          idProduct: "",
          productKey: "",
          description: "",
          unitMeasurement: 0,
          categories: [],
        };
        this.action = action;
      } else {
        try {
          this.loading = UtilFront.getLoadding();
          //await this.listAllCategories();
          await this.listAllUnitMeasurement();
          this.action = action;
        } catch (error) {
          this.error(error);
        } finally {
          this.loading.hide();
        }
      }
    },
    doAction() {
      if (this.action == 2) {
        this.add();
      } else if (this.action == 3) {
        this.update();
      }
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    async listAll() {
      await ProductsRequest.listAll()
        .then((res) => {
          let { data, success } = res.data.data;
          if (success) {
            this.$refs.tableProd.reloadComponent(data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", "Ocurrio un error interno.");
        })
        .finally(() => {});
    },
    add() {
      this.loading = UtilFront.getLoadding();
      this.sending = true;
      let product = {
        categories: this.model.categories,
        description: this.model.description,
        productKey: this.model.productKey,
        unitMeasurement: this.model.unitMeasurement,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
        },
      };
      ProductsRequest.add(product)
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success == true) {
            this.alert("success", "Add_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => this.error(error))
        .finally(() => {
          this.loading.hide();
          this.sending = false;
          this.listAll();
          this.changeAction(1);
        });
    },
    async update() {
      this.loading = UtilFront.getLoadding();
      let productUpdate = {
        idProduct: this.model.idProduct,
        description: this.model.description,
        productKey: this.model.productKey,
        unitMeasurement: this.model.unitMeasurement,
        categories: this.model.categories,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
        },
      };
      this.sending = true;
      await ProductsRequest.update(productUpdate)
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success) {
            this.alert("success", "Update_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
          this.sending = false;
          this.listAll();
          this.changeAction(1);
        });
    },
    edit(object) {
      this.model = {
        ...this.model,
        ...object,
      };
      this.model.unitMeasurement = object.unitMeasurement.toString();
      this.model.categories = this.model.categories.map((object) => {
        return {
          idCategory: object.idCategory,
        };
      });
      this.changeAction(3);
    },
    async listAllCategories() {
      await CategoryRequest.listAll()
        .then((res) => {
          let { data } = res.data;
          if (data.objects) {
            this.categories = data.objects.map((object) => {
              let fullName = object.name;
              if (
                object.name.trim().toLowerCase() !=
                object.description.trim().toLowerCase()
              ) {
                fullName += " " + object.description;
              }
              return {
                value: {
                  idCategory: object.idCategory,
                },
                text: fullName,
              };
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
        });
    },
    async listAllUnitMeasurement() {
      await ProductsRequest.listAllUnitMeasurement()
        .then((res) => {
          let { data, success } = res.data.data;
          if (success) {
            this.unitsMeasurement = data;
          } else {
            this.unitsMeasurement = [];
            this.$refs.alertMessageComponent.reloadComponent(message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
        });
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportProducts({});
    },
  },
  async created() {
    try {
      this.loading = UtilFront.getLoadding();
      await this.listAllUnitMeasurement();
      //await this.listAllCategories();
      await this.listAll();
      this.loading.hide();
    } catch (error) {
      this.error(error);
    } finally {
      this.loading.hide();
    }
  },
  watch: {
    "$i18n.locale": function() {
      this.headers = PRODUCTS();
    },
  },
  components: {
    TableComponent,
    ValidationProvider,
    ValidationObserver,
    AlertMessageComponent,
  },
};
</script>

<style>
.theme--light.v-messages {
  color: rgb(255 0 0);
}
</style>
