var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"containerbar"}},[_c('div',{staticClass:"rightbar"},[_c('div',{staticClass:"breadcrumbbar"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6 col-lg-6 col-xs-12 col-sm-12"},[_c('h4',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t("products.supplies")))]),_c('div',{staticClass:"breadcrumb-list"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/dashboard"}},[_vm._v(_vm._s(_vm.$t("products.home")))])]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v(" "+_vm._s(_vm.$t("products.configuration"))+" ")]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t(_vm.title)))])])])])]),_c('div',{staticClass:"col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button"},[(
              _vm.showAction(1) && _vm.withPermissionName(_vm.permissions.CAT_PRO_ADD_01)
            )?_c('button',{staticClass:"btn btn-outline-success",on:{"click":function($event){return _vm.changeAction(2)}}},[_c('i',{staticClass:"feather icon-plus mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.create_products"))+" ")]):_vm._e(),(_vm.showAction(2) || _vm.showAction(3))?_c('button',{staticClass:"btn btn-outline-success",on:{"click":function($event){return _vm.changeAction(1)}}},[_c('i',{staticClass:"feather icon-arrow-left mr-2"}),_vm._v(" "+_vm._s(_vm.$t("global.return_to"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"contentbar"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('AlertMessageComponent',{ref:"alertMessageComponent"}),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"card-title mb-0"},[_c('i',{staticClass:"feather icon-shopping-bag mr-2"}),_vm._v(_vm._s(_vm.$t(_vm.titleAction))+" ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAction(1)),expression:"showAction(1)"}]},[_c('TableComponent',{ref:"tableProd",attrs:{"headers":_vm.headers,"isButton":true,"isUpdate":_vm.withPermissionName(_vm.permissions.CAT_PRO_EDIT_02)},on:{"excelTable":_vm.excelTable,"updateRecord":_vm.edit}})],1),(_vm.showAction(2) || _vm.showAction(3) || _vm.showAction(4))?_c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doAction.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 pb-0"},[_c('h6',[_c('v-icon',{staticClass:"pb-1"},[_vm._v("mdi-numeric-1-box-outline")]),_vm._v(" "+_vm._s(_vm.$t("products.information_products"))+" ")],1)]),_c('div',{staticClass:"col-md-8"},[_c('validation-provider',{attrs:{"name":"causes","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":50,"error-messages":errors,"label":_vm.$t('label.causes'),"required":"","autocomplete":"off"},model:{value:(_vm.model.productKey),callback:function ($$v) {_vm.$set(_vm.model, "productKey", $$v)},expression:"model.productKey"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"unit_of_measure","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.unitsMeasurement,"error-messages":errors,"no-data-text":_vm.$t('label.no_results'),"label":_vm.$t('label.unit_of_measure')},model:{value:(_vm.model.unitMeasurement),callback:function ($$v) {_vm.$set(_vm.model, "unitMeasurement", $$v)},expression:"model.unitMeasurement"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"description","rules":"required|max:800"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","name":"description","label":_vm.$t('label.description'),"error-messages":errors,"required":"","counter":800,"maxlength":800,"rows":"3","row-height":"20"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-12 text-right"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":invalid || _vm.sending}},[_c('i',{staticClass:"feather icon-save mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.save_products"))+" ")])])])]}}],null,false,2956431638)})],1)])]):_vm._e()],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }